.contact_method {
  // style
  text-align: center;
  // box
  width: 400px;
  margin-bottom: 24px;
}

.contact_method-method {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact_method-name {
  color: $darkgray;
  font-size: larger;
}

.contact_method-details {
  // box
  margin: 12px;
  padding: 16px;
  border-radius: 12px;
  box-sizing: border-box;

  // style
  background: $red;
  color: white;
  span {
    font-size: larger;
  }
}

.contact_methods {
  // display
  display: flex;
  justify-content: center;
  // box
}

@media (max-width: 900px) {
  .contact_methods {
    flex-flow: column;
    align-items: center;
  }
  .contact_method-details {
    margin: 0;
    margin-top: 12px;
  }
}

@media (max-width: 400px) {
  .contact_method {
    width: 250px;
  }
}
