.hamburger_button {
  width: 90%;
  max-width: 300px;
  margin: 12px;
  margin-bottom: 0;
}

.header {
// position
  z-index: 2;
  position: sticky;
  top: 0;
  left: 0;
// box
  padding: 8px 24px;
  background: $darkgray;
// display
  display: flex;
  align-items: center;
  justify-content: space-between;

  ul {
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }
}

.header_button {
// box
  border: none;
  padding: 0 16px;
  border-radius: 8px;
  margin-left: 36px;
// style
  font-size: .8rem;
  font-weight: bold;
  color: black;
  background: $yellow;
// pseudo
  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  }
  
.header_button-2 {
// display
  display: none;
// box
  width: 80%;
  margin-bottom: 16px;
}

.header_contact {
// position
  position: absolute;
  top: 66px;
  right: 0;
// display
  display: flex;
  flex-flow: column;
  a {
  // style
    background: $red;
    color: white;
  // box
    margin-bottom: 12px;
    width: 250px;
    padding: 12px;
    border-radius: 8px;
  // pseudo
    &:hover { color: white; }
  }
}

.header_dropdown {
// position
  position: absolute;
  top: 40px;
// display
  display: none;
// box
  width: 200px;
  padding-left: 16px;
  margin-left: -16px;
// style
  background: $darkgray;
}
  
.header_dropdown-2 {
// position
  position: fixed;
  z-index: 3;
// display
  display: none;
  flex-flow: column;
  align-items: center;
// style
  background: $darkgray;
// box
  border-top: 1px solid black;
  width: 100%;
}

.header_dropdown-caret {
// box
  margin-left: 8px;
  width: 10px;
// style
  filter: invert(81%) sepia(4%) saturate(280%) 
  hue-rotate(314deg) brightness(90%) contrast(99%);
}

.header_dropdown-container {
// position
  position: relative;
// pseudo
  &:hover .header_dropdown, .dropdown-content { display: block; };
}

.header_dropdown-item {
// box
  height: 40px;
  width: 100%;
  padding: 8px 0;
  margin-top: 1em;
// text

  color: $logogray;
  line-height: 20px;
// style
  transition: padding 0.2s ease;
// pseudo
  &:hover { 
    color: white; 
    padding-left: 9px;
  }
  &:last-of-type { border: none; }
}
  
.header_dropdown-item-2 {
// box
  height: 32px;
  width: 250px;
  margin-left: 120px;
  margin-top: .8em;

// text
  color: $logogray;
  line-height: 20px;
// pseudo
  &:hover { 
    color: white;
    cursor: pointer;
  }
  &:first-of-type { margin-top: 8px; }
}

.header_hamburger {
// display
  display: none;
// box
  height: 20px;
  margin-left: 24px;
// style
  filter: invert(81%) sepia(4%) saturate(280%) 
  hue-rotate(314deg) brightness(90%) contrast(99%);
// pseudo
  &:hover { cursor: pointer; }
}

.header_img {
  height: 40px;
  filter: invert(86%) sepia(6%) saturate(216%) hue-rotate(314deg) brightness(88%) contrast(89%);
  margin-right: 8px;
}

.header_link {
// box
  height: 50px;
  margin-left: 40px;
// text
  color: #c1b9b9;
  line-height: 50px;
  font-weight: bold;
  margin-top: .9em;
// display
  display: flex;
  align-items: center;
// pseudo
  &:hover {
    cursor: pointer;
    color: white;
    img {
      filter: invert(99%) sepia(6%) saturate(26%) hue-rotate(268deg) brightness(107%) contrast(100%);
    }
  }
}

.header_logo {
// display
  display: flex;
  align-items: center;
// text
  color: $logogray;
  font-weight: bold;
  font-size: 1.3rem;
  letter-spacing: 2px;
// pseudo
  &:hover { cursor: pointer; }
}

.header_logo-img {
// box
  height: 44px;
  margin-right: 12px;
}

.header_nav-container {
// position
  position: relative;
// display
  display: flex;
  align-items: center;
  nav {
    display: flex;
  }
}

@media(max-width: 1000px) {
  .header {
    padding: 8px 12px;
  }
  .header_logo-img {
    height: 36px;
  }
  .header_logo-text {
    font-size: 1rem;
  }
  .header_link {
    height: 40px;
    font-size: .8rem;
    line-height: 40px;
  }
  .header_button {
  // box
    border: none;
    padding: 0 16px;
    border-radius: 8px;
  // style
    font-size: .8rem;
    font-weight: bold;
    color: black;
    background: $yellow;
  // pseudo
    &:hover {
      transform: scale(1.05);
      cursor: pointer;
    }
  }
}

@media(max-width: 800px) {
  .header_nav-container nav {
    display: none;
  }
  .header_link {
  // box
    padding-left: 24px;
    height: 30px;
    width: 250px;
  // text
    font-size: 1rem;
    font-weight: bold;
    line-height:30px;
    text-align: left;
  // pseudo
    &:first-of-type { margin-top: 12px; }
  }
  .no-highlight {
    &:hover { color: $logogray; }
    border: none;
  }
  .header_hamburger {
    display: initial;
  }
  .header_dropdown-2 {
    display: flex;
  }
}