@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
// font-family: 'Montserrat', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
// font-family: 'Courgette', cursive;

$red: #FB0001;
$darkgray: #4B4B4B;
$logogray: #c1b9b9;
$yellow: #f9cc2a;

* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

h1 {
  margin: 12px 16px;
  font-size: 2.8rem;
  text-align: center;
}

h2 {
  margin: 0 16px;
  font-size: 1.8rem;
}

h3 {
  padding: 36px;
  font-size: 2rem;
  text-align: center;
  color: $red;
}

p {
  // font-size: 1.2rem;
  font-size: larger;

}

span {
  font-size: 1.15rem;
  // font-size: larger;

}

@mixin b($color) {
  border: 2px solid $color;
}

@media (max-width: 700px) {
  h1 { font-size: 2rem; }
  h2 { font-size: 1.4rem; }
  h3 { font-size: 1.6rem;}
  p { font-size: 1rem; }
}

@media (max-width: 400px) {
  h1 { font-size: 1.6rem; }
  h2 { font-size: 1.1rem; }
  h3 { font-size: 1.3rem; }
  p { font-size: .8rem; }
  span { font-size: .8rem; }
}

@import './about';
@import './beforeandafter';
@import './blog';
@import './contact';
@import './footer';
@import './gallery';
@import './header';
@import './intro';
@import './landing';
@import './review';
@import './services';