.review {
// position
  position: relative;
// box
  max-width: 800px;
  padding: 0 24px;
}

.review_name {
  margin-bottom: 24px;
  color: $logogray;
}

.review_review {
// style
  font-family: 'Courgette', cursive;
  font-size: 1.8rem;
// box
  margin-bottom: 24px;
}

.review_star {
  height: 75px;
  margin: 0 4px;
}

.reviews {
// display
  display: flex;
  flex-flow: column;
  align-items: center;
// box
  padding-bottom: 24px;
// style
  text-align: center;
  color: white;
  // background-image: url('https://ak.picdn.net/shutterstock/videos/1021482712/thumb/1.jpg');
  background-image: url('./Assets/Backgrounds/Review.jpeg');
  background-attachment: fixed;
  background-size: cover;
  background-position-y: 70%;
  background-position-x: 50%;
}

@media (max-width: 550px) {
  .review_review {
    font-size: 1.5rem;
  }
  .review_star {
    height: 40px;
  }
  .reviews {
    background-attachment: initial;
  }
}