.ba {
  height: 50vw;
  width: 90vw;
  max-width: 716px;
  max-height: 400px;
}

.ba_image_container {
  height: 100%;
  max-width: 716px;
  max-height: 400px;
  user-select: none;
  position: relative;
}

.ba_image {
  // box
  height: 100%;
  width: 100%;

  max-width: 716px;
  max-height: 400px;
  position: absolute;
  inset: 0;
}

.ba_slider {
  height: 50vw;
  max-height: 400px;
  position: absolute;
  inset: 0;
}

.ba_slider_inner {
  height: 100%;
  position: relative;
}

.ba_slider_line {
  height: 100%;
  width: 4px;
  margin-left: -2px;
  background: white;
  opacity: 0.7;
  position: absolute;
  inset: 0;
}

.ba_slider_circle {
  height: 30px;
  width: 30px;
  margin-left: -15px;
  margin-top: -15px;
  border-radius: 100%;
  background: white;
  position: absolute;
  top: 50%;
  &:hover {
    cursor: pointer;
  }
}
