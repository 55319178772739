.footer {
// style
  background: $darkgray;
  color: white;
// box
  padding: 24px;
// display
  display: flex;
  justify-content: center;
}

.footer_child {
// display
  display: flex;
  align-items: center;
  
// box
  margin-top: 8px;
  font-size: larger;
  span {
  font-size: large;

  }
// pseudo
  &:hover { 
    cursor: pointer;
    color: white;
  }
}

.footer_children {
// box
  padding: 0 12px;
}

.footer_column {
// style
  color: $logogray;
  text-align: center;
// box
  width: 350px;
  margin: 0 48px;
  margin-bottom: 24px;
  border-radius: 12px;
}

.footer_column-title {
// box
  padding-bottom: 8px;
  border-bottom: 2px solid rgb(107, 107, 107);
  margin-bottom: 24px;
}

.footer_grandchild {
  text-align: left;
  margin-top: 1em;
  padding-left: 24px;
  &:hover {
    cursor: pointer;
    color: white;
  }
}

.footer_icon {
// box
  height: 36px;
  width: 36px;
  border-radius: 50%;
  margin-right: 12px;
// style
  filter: invert(86%) sepia(6%) saturate(216%) hue-rotate(314deg) brightness(88%) contrast(89%);
}

.footer_logo {
  height: 200px;
  filter: invert(100%);
  margin: 0 auto;
}

.footer_no-hover {
  &:hover { 
    cursor: default;
    color: inherit;
  }
}

@media(max-width: 1200px) {
  .footer {
    flex-wrap: wrap;
    max-width: 100vw;
  }
  
  .footer_column {
    padding: 12px;
    box-shadow: 0px 5px 10px rgb(44, 44, 44);
    margin: 12px;
  }
}