.bio {
// box
  max-width: 400px;
// display
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  // @include b(red);
}

.bio_bio {
  color: gray;
  font-size: 1rem;
  font-size: larger;
}

.bio_grid {
// box
  margin: 48px 0;
// display
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 400px));
  grid-gap: 60px;
}

.bio_img {
// box
  border-radius: 50%;
  height: 200px; 
  width: 200px;
  object-fit: cover;
  object-position: center;
}

.bio_name {
// box
  margin-top: 24px;
  // font-size: 1.6rem;
  padding: 0;
}

.bio_title {
// box
  margin-bottom: 12px;
  color: gray;
}

@media (max-width: 650px) {
  .bio_bio {
    width: 90%;
    margin: 0 auto;
  }
}

@media (max-width:1100px) {
  .bio {
    flex-flow: column;
    align-items: center;
    text-align: center;
  }
  .bio_name {
    margin-top: 12px;
    text-align: center;
  }
  .bio_title {
    margin-bottom: 12px;
  }
  .bio_bio {
    padding: 0 24px;
  }
}