.button_cta {
  // box
    border: none;
    margin-top: 12px;
    padding: 8px 24px;
    border-radius: 8px;
  // style
    background: $yellow;
  // text
    font-weight: bold;
    font-size: 1.3rem;
  // pseudo
    &:hover {
      transform: scale(1.05);
      cursor: pointer;
    }
  }
  
.intro {
// position
  position: relative;
// display
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
// size
  width: 100vw;
  height: 200px;
// style
  color: white;
  background: gray;
  background-image: url('./Assets/Backgrounds/Intro-min.png');
  background-position-x: 50%;
  background-position-y: 10px;
  background-size: cover;
  background-attachment: fixed;
  h2 { color: $logogray; }
}

.intro_gallery {
// box
  border: 8px solid white;
  max-height: 350px;
  width: auto;
  margin-top: 12px;
// style
  background: gray;
}

.mascot {
// position
  position: absolute;
  right: 150px;
  bottom: -60px;
}

@media(max-width: 1200px) {
  .mascot {
    display: none;
  }
  .intro {
    background-attachment: initial;
  }
}

@media(max-width: 900px) {
  .intro {
    height: 400px;
    text-align: center;
  }
}

@media(max-width: 500px) {
  .intro_gallery {
    height: 200px;
  }
}