.landing_services {
  // display
  display: flex;
  flex-flow: column;
  align-items: center;
  // text
    text-align: center;
}

.landing_title {
  text-align: center;
}

.landing_services-container {
  // display
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100vw;
    padding: 0 24px;

  // position
    margin-bottom: 40px;
}

.landing_service {
  margin: 0 24px;
  
  &:hover {
    cursor: pointer;
    color: $red;
  }
}

#landing_service-title {
  font-size: larger;
  
}

.landing_values {
// display
  display: flex;
  justify-content: center;
// box
  margin-top: -12px;
}

.value {
// box
  width: 300px;
  margin: 0 24px;
  margin-bottom: 24px;
// display
  display: flex;
  flex-flow: column;
  align-items: center;
}

.value_description {
// style
  font-size: 1rem;
  font-size: larger;

  color: gray;
}

.value_icon {
// box
  height: 175px;
  width: 175px;
  margin-bottom: 24px;
// style
  // background: gray;
}

.value_value {
// style
  font-size: 1.4rem;
  color: $darkgray;
// box
  margin-bottom: 12px;
}

@media(max-width: 1110px) {
  .landing_services-container {
    max-width: 600px;
    flex-wrap: wrap;
  }
  .landing_service { margin-bottom: 24px; }
  .landing_values {
    margin: 0 auto;
    max-width: 800px;
    display: flex;
    flex-wrap: wrap;
  }
  .value {
    margin-bottom: 36px;
  }
}

@media(max-width: 400px) {
  .service_icon-placeholder {
    height: 90px;
    width: 90px;
    margin-bottom: 8px;
  }
}