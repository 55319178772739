.service_icon {
  // box
  height: 100px;
  width: 100px;
  margin-bottom: 16px;
  filter: invert(26%) sepia(12%) saturate(19%) hue-rotate(339deg)
    brightness(100%) contrast(89%);
}

.service_main-picture {
  // box
  height: 70vw;
  width: 90vw;
  max-width: 716px;
  max-height: 400px;
  // display
  display: flex;
  justify-content: center;
}

.service_main-img {
  // width: 100%;
  height: 100%;
  max-width: 100%;
object-fit: scale-down;
}

.service_summary {
  // display
  display: flex;
  flex-flow: column;
  align-items: center;
  align-content: center;
  // box
  margin-bottom: 24px;

}

.service_summary-h3 {
  // style
  color: $red;
}

.service_summary-p {
  // box
  max-width: 716px;
  padding: 0 18px;
  margin-top: 10px;
  // style
  text-align: center;
  font-size: larger;

}

@media (max-width: 800px) {
  .service_gallery-box {
    margin: 0 1.6vw;
  }
}
